import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Wir sind alle mit Dialogboxen im Web vertraut. Von einfachen Aufforderungen, eine Aktion zu bestätigen, bis hin zu
Fenstern mit komplexen Inhalten — Dialoge sind ein integraler Bestandteil moderner Benutzeroberflächen im Web.`}</p>
    <p>{`Leider gab es lange Zeit kein natives Dialog-Element in HTML, weshalb zahlreiche
`}<a parentName="p" {...{
        "href": "https://accessuse.eu/en/modal-dialogs.html"
      }}>{`eigene Implementierungen mit schweren Barrierefreiheits-Problemen`}</a>{`
entwickelt wurden. Das änderte sich mit der Einführung des `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` Elements in
HTML 5.2 vor ein paar Jahren. Nachdem auch Safari endlich mit Version 15.4 das Element unterstützt, können nun
`}<a parentName="p" {...{
        "href": "https://caniuse.com/dialog"
      }}>{`alle modernen Browser`}</a>{` damit umgehen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAFpVfOm6SgH/8QAGRABAAIDAAAAAAAAAAAAAAAAAgEQAxES/9oACAEBAAEFAoEI5RxW7//EABcRAAMBAAAAAAAAAAAAAAAAAAIQETH/2gAIAQMBAT8BhXV//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABcQAQADAAAAAAAAAAAAAAAAABEAIDH/2gAIAQEABj8CWbX/xAAXEAEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEBAAE/ITiWsQHrXLbctt//2gAMAwEAAgADAAAAEB8f/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERMVH/2gAIAQMBAT8QSrzwp//EABYRAQEBAAAAAAAAAAAAAAAAABEQMf/aAAgBAgEBPxBOz//EABgQAQEBAQEAAAAAAAAAAAAAAAERACFB/9oACAEBAAE/EA8aUEwKXGEk5c9akfT3Ovcq7//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zwei weiße Sprechblasen auf pinkem Hintergrund.",
          "title": "Zwei weiße Sprechblasen auf pinkem Hintergrund.",
          "src": "/static/ebce27ac9b0deac34067a84ad6da1b6f/e5166/pexels-miguel-a-padrinan-speech-bubbles.jpg",
          "srcSet": ["/static/ebce27ac9b0deac34067a84ad6da1b6f/f93b5/pexels-miguel-a-padrinan-speech-bubbles.jpg 300w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/b4294/pexels-miguel-a-padrinan-speech-bubbles.jpg 600w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/e5166/pexels-miguel-a-padrinan-speech-bubbles.jpg 1200w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/b17f8/pexels-miguel-a-padrinan-speech-bubbles.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Miguel Á. Padriñán / pexels.com`}</em></p>
    <p>{`Ich zeige euch, wie einfach ihr einen barrierefreien Modal-Dialog mit dem nativen Element erstellen könnt. Bei gewissen
Browsern und Screenreadern gibt es noch kleinere Probleme, worauf ich am Ende des Artikels eingehen werde.`}</p>
    <h2>{`Was macht das `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` Element?`}</h2>
    <p>{`Das Dialog-Element erzeugt eine Popup-Box auf der Webseite, welche die Aufmerksamkeit der Nutzer:innen auf sich zieht.
Die `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/interactive-elements.html#the-dialog-element"
      }}>{`HTML-Spezifikation`}</a>{` beschreibt
das Element so:`}</p>
    <blockquote lang="en">
    The dialog element represents a part of an application that a user interacts with to perform a task, for example a dialog box, inspector, or window.
    </blockquote>
    <p>{`Ein typischer Anwendungsfall ist ein Modal-Dialog, der den Rest der Seite verdeckt und die Nutzer:innen zur Eingabe von
Daten auffordert. Ich habe eine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/dialog"
      }}>{`Demo`}</a>{` mit dem React-Framework
erstellt (`}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/DialogElement"
      }}>{`Quellcode`}</a>{`):`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/dialog" title="Demo Natives Dialog-Element"></iframe>
    <h2>{`Aufbau und Interaktion mit dem Dialog`}</h2>
    <p>{`Der grundlegende `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX-Code`}</a>{` meines Modal-Dialogs sieht so aus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dialog
    aria-labelledby='dialog-personal-info-heading'
    ref={formDialogRef}
    onClick={onFormDialogContainerClick}
>
    <h3 id="dialog-personal-info-heading">
        Personal Information
    </h3>
    <p>...</p>
    <form
        method="dialog"
        onClick={event => event.stopPropagation()}
    >
        // form elements
    </form>
</dialog>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Standardmäßig rendert der Browser den Dialog erst dann, wenn ihr die Eigenschaft `}<InlineCode mdxType="InlineCode">{`open`}</InlineCode>{` setzt,
um den Dialog sichtbar zu machen. Empfohlen wird jedoch, die Methode `}<InlineCode mdxType="InlineCode">{`.show()`}</InlineCode>{`
oder `}<InlineCode mdxType="InlineCode">{`.showModal()`}</InlineCode>{` zu verwenden, um den Dialog anzuzeigen.`}</p>
    <p>{`In meinem Fall will ich einen Modal-Dialog anzeigen, der den Rest der Seite verdeckt. In meiner funktionellen React-Komponente
erzeuge ich eine Referenz auf das HTML-Element mithilfe des `}<InlineCode mdxType="InlineCode">{`useRef`}</InlineCode>{` Hooks und öffne den Dialog beim
Klick auf eine Schaltfläche:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const formDialogRef = useRef<HTMLDialogElement>(null);

const onOpenFormDialogClick = () => {
    formDialogRef.current?.showModal();
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Um den Dialog zu schließen, verwende ich die Methode `}<InlineCode mdxType="InlineCode">{`.close()`}</InlineCode>{`. Wie meine Demo zeigt, kann auch
ein `}<InlineCode mdxType="InlineCode">{`<`}{`form`}{`>`}</InlineCode>{` Element den Dialog mithilfe des Attributs `}<InlineCode mdxType="InlineCode">{`method="dialog"`}</InlineCode>{`
schließen. Beim Abschicken des Formulars wird der Dialog geschlossen und für die Eigenschaft `}<InlineCode mdxType="InlineCode">{`returnValue`}</InlineCode>{`
der Wert der Schaltfläche gesetzt, mit der das Formular abgeschickt wurde.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<form method="dialog">
    <div className={styles.formField}>
        <label htmlFor="favMovie">Favorite movie:</label>
        <input id="favMovie" type="text" />
    </div>
    <button
        type="submit"
        value={DIALOG_CONFIRM_VALUE}
    >
        Confirm
    </button>
</form>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ihr könnt einen `}<span lang="en">{`Event Listener`}</span>{` für das `}<InlineCode mdxType="InlineCode">{`close`}</InlineCode>{` Ereignis des Dialogs
hinzufügen und somit eine Aktion abhängig vom `}<InlineCode mdxType="InlineCode">{`returnValue`}</InlineCode>{` ausführen.`}</p>
    <h2>{`Den Dialog und seinen Hintergrund stylen`}</h2>
    <p>{`Der Modal-Dialog wird standardmäßig zentriert und über dem restlichen Inhalt der Seite platziert. Browser wenden
jeweils ein eigenes Styling auf das `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` Element an, meist einen dicken schwarzen Rahmen.
Mit CSS könnt ihr das Aussehen des Dialogs individuell gestalten. Definiert zum Beispiel einen Schattenwurf und runde
Ecken:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog {
    border: 0.125rem solid var(--border-color);
    border-radius: 4px;
    box-shadow:
        0 11px 15px -7px #0003,
        0 24px 38px 3px #00000024,
        0 9px 46px 8px #0000001f;
    font-size: 1rem;
    max-width: min(18rem, 90vw);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ein geniales Feature des nativen Dialogs ist das CSS Pseudo-Element `}<InlineCode mdxType="InlineCode">{`::backdrop`}</InlineCode>{`. Damit könnt
ihr den Bereich hinter dem Modal-Dialog gestalten, etwa indem ihr die restliche Seite abdunkelt und verschwommen macht:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog::backdrop {
    background: rgba(36, 32, 20, 0.5);
    backdrop-filter: blur(0.25rem);
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Tastatur- und Mausbedienung`}</h2>
    <p>{`Beim Öffnen des Modal-Dialogs setzt der Browser automatisch den Fokus auf das erste, interaktive Element im Dialog.
Diese Verhaltensweise ist für die meisten Anwendungsfälle passend, etwa bei meinem Formular-Dialog. In manchen
Fällen wäre es jedoch besser, wenn man den Fokus auf den gesamten Dialog oder ein Textelement am Anfang setzen könnte.
Mehr dazu im `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/wiki/dialog--initial-focus,-a-proposal"
      }}>{`Vorschlag für initiale Fokus-Platzierung`}</a></p>
    <p>{`Solange der Modal-Dialog geöffnet ist, können Nutzer:innen mit dem restlichen Inhalt der Seite nicht interagieren. Das
bedeutet, dass Tastaturnutzer:innen den Dialog nicht mit der `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` Taste verlassen können. Auch der
virtuelle Cursor des Screenreaders (Pfeiltasten oder Swiping) kann sich nur innerhalb des Dialogs bewegen.`}</p>
    <p>{`Der Modal-Dialog kann standardmäßig mit der `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` Taste geschlossen werden. Nach dem Schließen wird
der Fokus automatisch zurück auf das Bedienelement gesetzt, mit dem der Dialog geöffnet wurde. Davon profitieren Tastatur-
und Screenreader-Nutzer:innen, die sofort an der Stelle weitermachen können, wo sie aufgehört hatten.`}</p>
    <p>{`Leider wird das `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` Element nicht automatisch geschlossen, wenn Nutzer:innen außerhalb des
Dialogs klicken. Um dieses Verhalten zu implementieren, können wir die Koordinaten des Klicks mit dem gerenderten Rechteck
des Dialogs vergleichen (danke für die tolle Idee, `}<a parentName="p" {...{
        "href": "https://blog.logrocket.com/using-the-dialog-element/"
      }}>{`Amit Merchant`}</a>{`):`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const isClickOutsideOfDialog = (dialogEl: HTMLDialogElement, event: React.MouseEvent): boolean => {
    const rect = dialogEl.getBoundingClientRect();
    return (event.clientY < rect.top
         || event.clientY > rect.bottom
         || event.clientX < rect.left
         || event.clientX > rect.right);
}

const onFormDialogContainerClick = (event: React.MouseEvent) => {
    const formDialogEl = formDialogRef.current;
    if (formDialogEl &&
        isClickOutsideOfDialog(formDialogEl, event)
    ){
        formDialogEl.close(DIALOG_CANCEL_VALUE);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das funktioniert meistens sehr gut. Ein Problem habe ich bei meiner Demo festgestellt: Wenn Nutzer:innen die native
Auswahlliste öffnen, würde der Algorithmus dies als Klick außerhalb des Dialogs werten. Aus diesem Grund habe ich
einen `}<span lang="en">{`Click Event Listener`}</span>{` für das `}<InlineCode mdxType="InlineCode">{`form`}</InlineCode>{` Element registriert und nutze
die Methode `}<InlineCode mdxType="InlineCode">{`.stopPropagation()`}</InlineCode>{`.`}</p>
    <h2>{`Probleme mit der Barrierefreiheit bei manchen Screenreadern`}</h2>
    <p>{`Das native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` Element funktioniert super mit den meisten Screenreadern und Browsern.
Leider gibt es noch ein paar kleine Probleme, wie die Barrierefreiheits-Tests meiner
`}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/dialog"
      }}>{`Demo`}</a>{` aufzeigen:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Windows 10, Google Chrome 103.0.5060.114, NVDA 2022.1`}</strong>{`: Wenn der Dialog geöffnet wird und Fokus erhält, liest der
Screenreader die Dialog-Rolle, die Überschrift (dank `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{`), den ersten Textabsatz und
die fokussierte Auswahlliste vor. Die Fokus-Reihenfolge und der virtuelle Cursor des Screenreaders wird auf die Inhalte
im Dialog beschränkt.`}</li>
      <li parentName="ul"><strong parentName="li">{`Windows 10, Firefox 102.0.1, NVDA 2022.1`}</strong>{`: Identisch zu Google Chrome, mit der Ausnahme dass die Schaltfläche, mit
welcher der Dialog geöffnet wurde, Teil der Fokus-Reihenfolge ist. Wahrscheinlich ein Firefox-Bug, der in Zukunft
behoben wird.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 12, Google Chrome 103.0.5060.71, TalkBack`}</strong>{`: Der Screenreader liest nur die fokussierte
Auswahlliste vor. Der virtuelle Cursor (z.B. nach rechts wischen) wird auf die Inhalte im Dialog beschränkt.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 12, Firefox 102.2.1, TalkBack`}</strong>{`: Der Screenreader liest nur die fokussierte Auswahlliste
vor. Der virtuelle Cursor wird nicht auf die Inhalte im Dialog beschränkt. Das hat wahrscheinlich damit zu tun, dass
Firefox immer noch nicht die Eigenschaft `}<a parentName="li" {...{
          "href": "https://caniuse.com/mdn-api_element_ariamodal"
        }}>{`aria-modal`}</a>{` unterstützt.`}</li>
      <li parentName="ul"><strong parentName="li">{`iPhone 8, iOS 15.5, Safari, VoiceOver`}</strong>{`: Der Screenreader liest nur die fokussierte Auswahlliste vor. Der virtuelle
Cursor kann auf die Inhalte im Header gesetzt werden, jedoch nicht auf die Inhalte im Hauptinhaltsbereich, der direkt
unter dem Dialog liegt.`}</li>
    </ul>
    <h2>{`Fazit`}</h2>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` Element ist einfach zu handhaben und standardmäßig barrierefrei, abgesehen
von kleineren Problemen. Für manche Anwendungsfälle dürfte daher aktuell ein robuster, selbst gebauter Dialog wie
`}<a parentName="p" {...{
        "href": "https://github.com/KittyGiraudel/a11y-dialog"
      }}>{`a11y-dialog`}</a>{` besser geeignet sind. Ich bin aber optimistisch was die
Zukunft des nativen Elements betrifft.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog"
        }}>{`The Dialog element (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/whatwg/html/wiki/dialog--initial-focus,-a-proposal"
        }}>{`Dialog initial focus, a proposal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nngroup.com/articles/modal-nonmodal-dialog/"
        }}>{`Modal & Nonmodal Dialogs: When (& When Not) to Use Them`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 07.03.2023</PostUpdateTitle>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/commit/a9f103c9f7bd09ef712990194638c75db1f50e3c"
      }}>{`HTML-Spezifikation hat eine wichtige Aktualisierung`}</a>{`
hinsichtlich des initialen Fokus-Managements erhalten. Es wird möglich sein, dem `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` Element
selbst den Fokus zu geben, wenn es das Attribut `}<InlineCode mdxType="InlineCode">{`autofocus`}</InlineCode>{` gesetzt hat.`}</p>
    <p>{`Sicher, es wird einige Zeit dauern, bis die Browser-Hersteller diese Änderungen umsetzen. Aber es gibt keinen Grund, noch länger
zu warten! Ich sehe es `}<a parentName="p" {...{
        "href": "https://www.scottohara.me/blog/2023/01/26/use-the-dialog-element.html"
      }}>{`wie Scott O'Hara`}</a>{`: Es ist
an der Zeit, von benutzerdefinierten Dialogen wegzukommen und stattdessen das native Dialog-Element zu verwenden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      